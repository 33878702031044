<template>
  <div class="home-container">
    <!-- 头部 -->
    <van-nav-bar title="占金新闻" fixed />
    <van-pull-refresh v-model="isLoading" :disabled='finished' @refresh="onRefresh">
      <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      >
      <ArticleInfo
      v-for="item in artlist"
      :key="item.id"
      :title="item.title"
      :author="item.aut_name"
      :cmtCount="item.comm_count"
      :time="item.pubdate"
      :cover="item.cover"
      >
      </ArticleInfo>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// 按需导入API接口
import { getArticleListAPI } from '@/api/articleAPI.js'

import ArticleInfo from '@/components/Article/ArticleInfo.vue'

export default {
  name: 'Home',
  data() {
    return {
      page: 1,
      limit: 10,
      artlist: [],
      // loading加载状态
      loading: true,
      // 是否加载完毕
      finished: false,
      // 下拉刷新
      isLoading: false
    }
  },
  created() {
    this.initArticleList()
  },
  methods: {
    // 封装获取文章列表数据的方法
    async initArticleList(isRefresh) {
      // 发起get请求，获取文章列表数据
      const { data: res } = await getArticleListAPI(this.page, this.limit)
      if (isRefresh) {
        // 证明是下拉刷新，新数据在前，旧数据在后
        this.artlist = [...res, ...this.artlist]
        this.isLoading = false
      } else {
        // 如果是下拉加载更多;旧数据在前，新数据在后
        this.artlist = [...this.artlist, ...res]
        this.loading = false
      }

      if (res.length === 0) {
        this.finished = true
      }
    },
    // 只要onload被调用，就请求下一页数据
    onLoad() {
      console.log('触发load事件')
      // 1.让页码值 +1
      this.page++
      // 2.重新请求接口获取数据
      this.initArticleList()
    },
    onRefresh() {
      console.log('触发了下拉刷新')
      // 1.页码+1
      this.page++
      // 2.重新获取接口数据
      this.initArticleList(true)
    }
  },
  components: {
    ArticleInfo
  }
}
</script>

<style lang="less" scoped>
  .home-container{
    padding: 46px 0 50px 0;
  }
  .van-nav-bar{
    background-color:rgba(255, 187, 0,);
  }
  /deep/.van-nav-bar__title{
    color: rgb(8, 8, 8);
    font-weight: bold;
  }
</style>
